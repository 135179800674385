<template>
	<div>
		<div class="notifications">
			<div class="notifications-inner red" v-if="type == 'payment-failed'">
				<b>Payment failed</b>
			</div>
			<div class="notifications-inner yellow" v-else-if="type == 'payment-processing'">
				<b>Payment Processing</b>
			</div>
			<div v-else-if="(type == 'booking-success' || type == 'booking-issue') && tripItem">
				<div class="notifications-inner" :class="tripItem.bookingStatus.code == 'booked' ? 'green' : 'issue-blue'">
					{{ tripItem.name }} / 
					<b v-if="type == 'booking-success'">Booking Success</b>
					<b v-else-if="type == 'booking-issue'">Something went wrong</b>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="left" :class="[type, tripItem ? tripItem.type : '' ]">
			</div>
			<div class="right" :class="[type, tripItem ? tripItem.type : '' ]">
				<div class="wrapper" v-if="type == 'payment-failed'">
					<div class="message">
						<div class="main">Try again</div>
						<div class="sub" v-html="message ? message : 'We couldn\'t take your payment'"></div>
					</div>
				</div>
				<div class="wrapper" v-else-if="type == 'payment-processing'">
					<div class="message">
						<div class="main">Check back later</div>
						<div class="sub" v-html="message ? message : 'Your payment is still processing'"></div>
					</div>
				</div>

				<div class="wrapper" v-else-if="tripItem && (type == 'booking-success' || type == 'booking-issue')">
					<div class="contents flight" v-if="tripItem.type == 'flight'">
						<div class="gradient"></div>
						<div class="type">{{ tripItem.type }}</div>
						<img v-if="type == 'booking-success'" src="@/assets/alerts/alert-green.png" alt="Result Status Success Icon" class="status">
						<img v-if="type == 'booking-issue'" src="@/assets/alerts/alert-issue-blue.png" alt="Result Status Issue Icon" class="status">
						<div>
							<div :set="arrivalDisplay = formatDateWithoutYear(tripItem.data.flightsDetails.arrival_display)" :let=" departureDisplay = formatDateWithoutYear(tripItem.data.flightsDetails.departure_display)">
								<img src="@/assets/checkout/flight-icon.png" alt="flight icon" class="icon"/>
								<div class="info">{{ departureDisplay }} <span v-if="arrivalDisplay != departureDisplay">- {{ arrivalDisplay }}</span></div>
							</div>
						</div>
					</div>
					<div class="contents tour" v-else-if="tripItem.type == 'tour'" :style="{'background-image': 'url(' + tourImage + ')'}">
						<div class="gradient"></div>
						<div class="type">{{ tripItem.type }}</div>
						<img v-if="type == 'booking-issue'" src="@/assets/alerts/alert-issue-blue.png" alt="Result Status" class="status">
						<img v-else src="@/assets/alerts/alert-green.png" alt="Result Status" class="status">
						<div>
							<div class="info">
								<img src="@/assets/tours/day-icon-white.png" alt="Sunset icon" width="19px"/>
								<span v-if="tripItem.data.length == 1">
									Day trip
								</span>
								<span v-else>
									{{ tripItem.data.length }} days
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import helpers from '@/helpers/helpers.js';

	export default {
		name: 'BookingResult',
		props: {
			// Defines which type of booking result to show. Possible values: 'payment-processing', 'payment-fail' 'booking-success', 'booking-issue'
			// If booking-success' or 'booking-issue' is set, then it will use the "tripItem" prop to show the booking details.
			type: {
				type: String,
				required: true,
				default: 'processing' 
			},
			message: {
				type: String,
				required: false,
				default: null
			},
			tripItem: { // OPTIONAL. Only used for booking success and booking issue
				type: Object,
				required: false,
				default: null
			}
		},
		data() {
			return {
			}
		},
		computed: {
			tourImage() {
				if(this.tripItem.data.images && this.tripItem.data.images.length > 0) {
					let imagesPriority = [
						"BANNER_HIRES",
						"BANNER", 
						"BANNER_DESKTOP",
						"LARGE_SQUARE", 
					];
					let orderedImages = this.tripItem.data.images.sort((a, b) => {
						// Order based on imagesPriority
						let aIndex = imagesPriority.indexOf(a.type);
						let bIndex = imagesPriority.indexOf(b.type);
						if(aIndex > bIndex) {
							return -1;
						}
						if(aIndex < bIndex) {
							return 1;
						}
						return -1;
					});
					return orderedImages[0].image_href;
				}
				return null;
			}
		},
		methods: {
			formatDateWithoutYear: (date) => helpers.formatDateWithoutYear(date),
			getTypeImage(type) {
				return require('../../assets/trip/' + type + '-icon.png');
			},
		}
	}
</script>

<style scoped>
.card {
	border-radius: 10px;
	border: 1px solid #E5E5E5;
	height: 160px;
	overflow: hidden;
	display: flex;
	max-width: 355px;
	box-sizing: border-box;
}
.card .left {
	width: 95px;
	height: 100%;
	background-size: 52px;
	background-repeat: no-repeat;
	background-position: center;
}
.card .left.payment-failed {
	background-color: #FA5252;
	background-image: url('../../assets/checkout/payment-failed.png');
}
.card .left.payment-processing {
	background-color: #FFBF19;
	background-image: url('../../assets/checkout/payment-processing.png');
}
.card .left.booking-issue {
	background-color: #4B94D8;
	background-image: url('../../assets/checkout/booking-issue.png');
}
.card .left.booking-success.flight {
	background-color: #06D6A0;
	background-image: url('../../assets/checkout/flight-success.png');
}
.card .left.booking-success.tour {
	background-color: #06D6A0;
	background-image: url('../../assets/checkout/tour-success.png');
}
.card .right {
	background: #FCFCFE;
	border-radius: 10px;
	width: 100%;
}
.card .right .wrapper {
	display: flex;
	align-items: center;
	color: black;
	width: 100%;
	height: 100%;
}
.card .right .message {
	padding: 15px;
}
.card .right .message .main {
	font-weight: 700;
	font-size: 16px;
}
.card .right .message .sub {
	font-weight: 400;
	font-size: 12px;
}
.card .right .contents {
	padding: 15px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	position: relative;
}

.card .right .contents .type {
	position: absolute;
	right: 15px;
	top: 10px;
	background: #7F3D9E;
	border-radius: 9px;
	font-weight: 700;
	font-size: 11px;
	line-height: 13px;
	color: #FFFFFF;
	text-transform: uppercase;
	padding: 2px 6px;
}
.card .right .contents .icon {
	position: absolute;
	right: 26px;
	top: 35px;
	width: 28px;
}
.card .right .contents .status {
	position: absolute;
	right: 15px;
	bottom: 15px;
	width: 16px;
}
.card .right .contents .info {
	position: absolute;
	left: 15px;
	bottom: 15px;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	color: #FFFFFF;
}
.card .right .contents.flight .gradient,
.card .right .contents.tour .gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 39, 42, 0) 69%,  rgba(0, 39, 42, 0.9) 100%);
}
.card .right .contents.flight {
	background-image: url('../../assets/trip/plane-image.png');
}
.card .right.booking-issue .contents {
  filter: grayscale(100%);
}
</style>